.logo {
  width: 80%;
}

.navbar {
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  flex-direction: row;
}

.activeLink {
  color: #45ba36;
  text-decoration: none;
  margin-right: 30px;
}

.link:hover,
.activeLink:hover {
  text-decoration: none;
  color: #45ba36;
}

.link {
  color: #505050;
  text-decoration: none;
  margin-right: 30px;
}
