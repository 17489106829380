.button {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-top: 16px;
}

.label {
  margin-top: auto;
  margin-bottom: auto;

  font-weight: 600;
}

.fileName {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  font-size: small;
}
