.dropdownButton {
    background-color: white;
    border: 1px solid #45ba36;
    color: #45ba36;
    width: 15rem;
    border-radius: 6px;
    text-transform: capitalize !important;
    z-index: 1;
  }
  
  .dropdownLabel {
    color: rgb(61, 59, 59);
    text-transform: capitalize;
    font-size: 22px;
  }

  .dropdownItem {
    width: 100%;
  }