.name {
  display: flex;
  flex-direction: row;
}

.divider {
  width: 40px;
}

.form {
  border-width: 0.5px;
  border-color: #45ba36;
  border-style: solid;
  padding: 1rem;
}