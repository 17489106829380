.row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}

.selectView {
    width: 38rem;
}

.main {
    margin: 0;
    padding: 1rem !important;
    border: 1px solid #45ba36;
    border-radius: 8px;
}

.checkbox {
    margin-left: 1rem;
    padding-bottom: 0.5rem;
}

.divider {
    width: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}