.name {
  display: flex;
  flex-direction: row;
}

.divider {
  width: 40px;
}

.box {
  border-width: 0.5px;
  border-color: #45ba36;
  border-style: solid;
  padding: 1rem;
  margin-bottom: 2rem;
}
