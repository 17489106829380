.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.selectView {
    width: 24rem;
}

.divider {
    width: 16px;
}
