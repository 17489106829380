.row {
    display: flex;
    flex-direction: row;
    margin-top: 1.2rem;
}

.divider {
    width: 40px;
}

.dropdownButton {
    background-color: white;
    border: 1px solid #45ba36;
    color: #45ba36;
    width: 36rem;
    border-radius: 6px;
    text-transform: capitalize !important;
    z-index: 1;
}

.dropdownLabel {
    color: white;
    text-transform: uppercase;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem;
}

.dropdownItem {
    width: 36rem;
}

.form {
    border-width: 1px;
    border-color: #45ba36;
    border-style: solid;
    padding: 1rem;
}