.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 0;
    height: 6rem;
    flex: 1;
    margin: 0;
    border-bottom: 15px solid #45ba36;
    border-top: 1px solid #45ba36;
    padding: 3rem;
}

.main {
    margin: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;

}

.coursesView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

